import { isNonEmptyString } from '@thesavingsgroup/utils/isNonEmptyString';
import i18n from 'i18next';
import {
  get as lodashGet,
  gt,
  head,
  isEmpty,
  isEqual,
  isObject,
  toString,
} from 'lodash';
import { StateCreator } from 'zustand';

import { isInEnum } from '../../utils/isInEnum';
import { DashboardVehicle } from '../dashboardStore/types/DashboardVehicle';
import { ApplicationType } from './enums/ApplicationType';
import { PurchaseType } from './enums/PurchaseType';
import { RefinanceGoal } from './enums/RefinanceGoal';
import { SearchVehicle } from './enums/SearchVehicle';
import { VehicleCondition } from './enums/VehicleCondition';
import { VehicleImageType } from './enums/VehicleImageType';
import { ApplyState } from './types/ApplyState';
import { ApplyStateDependencies } from './types/ApplyStateDependencies';
import { Vehicle } from './types/Vehicle';
import { VehicleCriteriaValue } from './types/VehicleCriteriaValue';
import { VehicleImage } from './types/VehicleImage';
import { VehicleMake } from './types/VehicleMake';
import { VehicleModel } from './types/VehicleModel';
import { VehicleTrim } from './types/VehicleTrim';

export const keysToPersist = [
  'applicationType',
  'make',
  'mileage',
  'model',
  'payoffAmount',
  'plate',
  'purchasePrice',
  'purchaseType',
  'refinanceGoal',
  'searchVehicle',
  'state',
  'trim',
  'vehicleCondition',
  'vin',
  'year',
];

export const applyStore =
  ({
    useApiStore,
    useDashboardStore,
    useParameterStore,
  }: ApplyStateDependencies): StateCreator<ApplyState> =>
  (set, get) => ({
    applicationType: null,
    fetchVehicleFailedMessage: null,
    fetchVehicleImageByTrimFailed: false,
    fetchVehicleMakesFailedMessage: null,
    fetchVehicleModelsFailedMessage: null,
    fetchVehicleSuccess: false,
    fetchVehicleTrimsFailedMessage: null,
    make: null,
    makes: [],
    mileage: null,
    model: null,
    models: [],
    payoffAmount: null,
    plate: null,
    purchasePrice: null,
    purchaseType: null,
    refinanceGoal: null,
    searchVehicle: null,
    state: null,
    submitCompleted: false,
    submitFailed: false,
    submitFailedKey: null,
    trim: null,
    trims: [],
    vehicleCondition: null,
    vehicleId: null,
    vehicleImage: null,
    vin: null,
    year: null,

    computed: {
      get hasValidApplicationType() {
        return isInEnum(get().applicationType, ApplicationType);
      },
      get hasValidPayoffAmount() {
        return gt(Number(get().payoffAmount), 0);
      },
      get hasValidPurchasePrice() {
        return gt(Number(get().purchasePrice), 0);
      },
      get hasValidPurchaseType() {
        return isInEnum(get().purchaseType, PurchaseType);
      },
      get hasValidRefinanceGoal() {
        return isInEnum(get().refinanceGoal, RefinanceGoal);
      },
      get hasValidVehicleCondition() {
        return isInEnum(get().vehicleCondition, VehicleCondition);
      },
      get hasValidVehicleLicensePlate() {
        const { plate, state } = get();

        return [plate, state].every(isNonEmptyString);
      },
      get hasValidVehicleMake() {
        const { make } = get();

        return (
          isObject(make) &&
          isNonEmptyString(make?.label) &&
          isNonEmptyString(make?.value)
        );
      },
      get hasValidVehicleMileage() {
        return gt(Number(get().mileage), 0);
      },
      get hasValidVehicleModel() {
        const { model } = get();

        return (
          isObject(model) &&
          isNonEmptyString(model?.label) &&
          isNonEmptyString(model?.value)
        );
      },
      get hasValidVehicleSearch() {
        return isInEnum(get().searchVehicle, SearchVehicle);
      },
      get hasValidVehicleTrim() {
        const { trim } = get();

        return (
          isObject(trim) &&
          isNonEmptyString(trim?.label) &&
          isNonEmptyString(trim?.value)
        );
      },
      get hasValidVehicleVin() {
        const { vin } = get();

        return isNonEmptyString(vin);
      },
      get hasValidVehicleYear() {
        const { year } = get();

        return gt(Number(year), 0);
      },
      get submitPayload() {
        const {
          applicationType,
          make,
          mileage,
          model,
          payoffAmount,
          plate,
          purchasePrice,
          purchaseType,
          refinanceGoal,
          state,
          trim,
          vehicleCondition,
          vin,
          year,
        } = get();

        const { leadChannelCode } = useParameterStore.getState();

        return {
          // important to prefer `purchaseType` over `applicationType` as the
          // `applicationType` always has a value (REFINANCE or PURCHASE),
          // but the purchase type is a more specific value that we want to
          // use if it's available (DEALER, LEASE_BUYOUT, PRIVATE_PARTY, etc)
          applicationType: purchaseType || applicationType,
          isNew: isEqual(vehicleCondition, VehicleCondition.NEW),
          leadChannelCode,
          make: make?.label,
          mileage: Number(mileage),
          model: model?.label,
          payoffAmount: payoffAmount ? Number(payoffAmount) : null,
          plate,
          province: state,
          purchasePrice: purchasePrice ? Number(purchasePrice) : null,
          refinanceGoal,
          trim: trim?.label,
          trimId: trim?.value,
          vin,
          year: Number(year),
        };
      },
      get yearMakeModel() {
        const { year, make, model } = get();

        const makeLabel = lodashGet(make, 'label');
        const modelLabel = lodashGet(model, 'label');

        return `${year} ${makeLabel} ${modelLabel}`;
      },
    },

    clear: () => {
      set({
        fetchVehicleFailedMessage: null,
        fetchVehicleImageByTrimFailed: false,
        fetchVehicleMakesFailedMessage: null,
        fetchVehicleModelsFailedMessage: null,
        fetchVehicleSuccess: false,
        fetchVehicleTrimsFailedMessage: null,
        makes: [],
        models: [],
        trims: [],
        vehicleImage: null,
      });
    },
    fetchVehicleBy: async (vehicleCriteriaValue: VehicleCriteriaValue) => {
      const { searchVehicle } = get();

      const { plate = null, state = null, vin = null } = vehicleCriteriaValue;

      set({
        fetchVehicleFailedMessage: null,
        fetchVehicleSuccess: false,
        makes: [],
        models: [],
        trims: [],
        vehicleImage: null,
      });

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .post<never, Vehicle[]>('/vehicles/search', {
          criteria: searchVehicle,
          value: vehicleCriteriaValue,
        })
        .then((vehicles) => {
          const vehicle = head(vehicles);

          const make: VehicleMake = {
            label: lodashGet(vehicle, 'make'),
            value: lodashGet(vehicle, 'makeId'),
          };

          const model: VehicleModel = {
            label: lodashGet(vehicle, 'model'),
            value: lodashGet(vehicle, 'modelId'),
          };

          const trim: VehicleTrim = {
            label: lodashGet(vehicle, 'trim'),
            value: lodashGet(vehicle, 'trimId'),
          };

          const year = lodashGet(vehicle, 'year');

          set({
            fetchVehicleSuccess: true,
            make,
            model,
            plate,
            state,
            trim,
            vin,
            year,
          });
        })
        .catch((error) => {
          const errorStatus = toString(lodashGet(error, 'response.status'));
          const defaultMessage = i18n.t('errors.fetchVehicleBy.defaultMessage');

          set({
            fetchVehicleFailedMessage: lodashGet(
              i18n.t('errors.fetchVehicleBy.statusMessage'),
              errorStatus,
              defaultMessage,
            ),
            make: null,
            model: null,
            plate: null,
            state: null,
            trim: null,
            vin: null,
            year: null,
          });
        });
    },
    fetchVehicleImageByTrim: async (trim: VehicleTrim | null) => {
      const { year, make, model } = get();

      const makeValue = lodashGet(make, 'value');
      const modelValue = lodashGet(model, 'value');
      const trimValue = lodashGet(trim, 'value');
      if (!year || !makeValue || !modelValue || !trimValue) {
        return;
      }

      const portalHttpRequest = useApiStore.getState().portalApi();

      set({ fetchVehicleImageByTrimFailed: false, vehicleImage: null });

      return portalHttpRequest
        .get<never, VehicleImage[]>(
          `vehicles/years/${year}/makes/${makeValue}/models/${modelValue}/trims/${trimValue}/images`,
        )
        .then((images) => {
          const vehicleImage = images.find(
            (image) => image.vehicleImageType === VehicleImageType.DEFAULT,
          );

          const vehicleImageURL = lodashGet(vehicleImage, 'url', null);

          set({
            fetchVehicleImageByTrimFailed: isEmpty(vehicleImageURL),
            vehicleImage: vehicleImageURL,
          });
        })
        .catch(() => set({ fetchVehicleImageByTrimFailed: true }));
    },
    fetchVehicleMakes: async () => {
      const { year } = get();

      set({
        fetchVehicleMakesFailedMessage: null,
        makes: [],
        models: [],
        trims: [],
      });

      if (!year) {
        return;
      }

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .get<never, VehicleMake[]>(`/vehicles/years/${year}/makes`)
        .then((makes) => set({ makes }))
        .catch(() =>
          set({
            fetchVehicleMakesFailedMessage: i18n.t(
              'errors.fetchVehicleMakes.defaultMessage',
            ),
          }),
        );
    },
    fetchVehicleModels: async () => {
      const { year, make } = get();

      set({
        fetchVehicleModelsFailedMessage: null,
        models: [],
        trims: [],
      });

      const makeValue = lodashGet(make, 'value');
      if (!year || !makeValue) {
        return;
      }

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .get<never, VehicleModel[]>(
          `/vehicles/years/${year}/makes/${makeValue}/models`,
        )
        .then((models) => set({ models }))
        .catch(() =>
          set({
            fetchVehicleModelsFailedMessage: i18n.t(
              'errors.fetchVehicleModels.defaultMessage',
            ),
          }),
        );
    },
    fetchVehicleTrims: async () => {
      const { year, make, model } = get();

      set({ fetchVehicleTrimsFailedMessage: null, trims: [] });

      const makeValue = lodashGet(make, 'value');
      const modelValue = lodashGet(model, 'value');
      if (!year || !makeValue || !modelValue) {
        return;
      }

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .get<never, VehicleTrim[]>(
          `/vehicles/years/${year}/makes/${makeValue}/models/${modelValue}/trims`,
        )
        .then((trims) => set({ trims }))
        .catch(() =>
          set({
            fetchVehicleTrimsFailedMessage: i18n.t(
              'errors.fetchVehicleTrims.defaultMessage',
            ),
          }),
        );
    },
    resetSubmitValues() {
      set({
        submitCompleted: false,
        submitFailed: false,
        submitFailedKey: null,
      });
    },
    setApplicationType: (value: ApplicationType) => {
      const { applicationType } = get();

      const applicationTypeChanged = applicationType !== value;

      set({
        applicationType: value,

        ...(applicationTypeChanged && {
          payoffAmount: null,
          purchasePrice: null,
          purchaseType: null,
          refinanceGoal: null,
        }),
      });
    },
    setMakeByValue: (value: string) => {
      const { makes } = get();

      const make = makes.find((make) => make.value === value) || null;

      set({ make, model: null, trim: null });
    },
    setMileage: (value: string) => {
      set({ mileage: value });
    },
    setModelByValue: (value: string) => {
      const { models } = get();

      const model = models.find((model) => model.value === value) || null;

      set({ model, trim: null });
    },
    setPayoffAmount: (value: string) => {
      set({ payoffAmount: value });
    },
    setPurchasePrice: (value: string) => {
      set({ purchasePrice: value });
    },
    setPurchaseType: (value: PurchaseType) => {
      set({ purchaseType: value });
    },
    setRefinanceGoal: (value: RefinanceGoal) => {
      set({ refinanceGoal: value });
    },
    setSearchVehicle: (value: SearchVehicle) => {
      const { searchVehicle } = get();

      const searchVehicleChanged = searchVehicle !== value;

      set({
        searchVehicle: value,

        ...(searchVehicleChanged && {
          make: null,
          model: null,
          plate: null,
          state: null,
          trim: null,
          vin: null,
          year: null,
        }),
      });
    },
    setTrimByValue: (value: string) => {
      const { trims } = get();

      const trim = trims.find((trim) => trim.value === value) || null;

      set({ trim });
    },
    setVehicleCondition: (value: VehicleCondition) => {
      set({ vehicleCondition: value });
    },
    setYear: (year: string) => {
      set({
        make: null,
        model: null,
        trim: null,
        year,
      });
    },
    submit() {
      const {
        computed: { submitPayload },
        resetSubmitValues,
      } = get();

      const portalHttpRequest = useApiStore.getState().portalApi();

      resetSubmitValues();

      return portalHttpRequest
        .post<never, DashboardVehicle>(`/2.0/apply`, submitPayload)
        .then((vehicle: DashboardVehicle) => {
          // Add the new vehicle to the dashboard store
          // so that it appears in the garage without having to refetch
          useDashboardStore.getState().addVehicle(vehicle);

          // Set the vehicle ID so that the user can be redirected to the applicant workflow
          set({ vehicleId: vehicle.id });
        })
        .catch((error) => {
          const errorStatus = toString(lodashGet(error, 'response.status'));

          set({
            submitFailed: true,
            submitFailedKey: lodashGet(
              i18n.t('errors.apply.statusMessage'),
              errorStatus,
            )
              ? `errors.apply.statusMessage.${errorStatus}`
              : 'errors.apply.defaultMessage',
          });
        })
        .finally(() => set({ submitCompleted: true }));
    },
  });
