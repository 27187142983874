import { isNonEmptyString } from '@thesavingsgroup/utils/isNonEmptyString';
import { every, toNumber } from 'lodash';

import { DEFAULT_PREVIOUS_INCOME_MONTHS_AT } from '../../applicantStore/constants/income';
import { EmploymentTypeEnum } from '../../applicantStore/enums/EmploymentTypeEnum';
import { IncomeFrequencyEnum } from '../../applicantStore/enums/IncomeFrequencyEnum';
import { incomeFrequencyToAPI } from '../../applicantStore/functions/incomeFrequencyToAPI';
import { CoapplicantIncomesDto } from '../dto/CoapplicantIncomesDto';
import { CoapplicantState } from '../types/CoapplicantState';

export const toCoapplicantIncomesDto = (
  state: CoapplicantState,
): CoapplicantIncomesDto => {
  const coapplicantIncomesDto: CoapplicantIncomesDto = {
    additional: null,
    current: {
      amount: toNumber(state.coapplicantCurrentIncomeGrossAnnual),
      employerName: state.coapplicantCurrentIncomeEmployerName,
      employmentType: state.coapplicantCurrentIncomeEmploymentType,
      incomeFrequency: incomeFrequencyToAPI(IncomeFrequencyEnum.ANNUALLY),
      jobTitle: state.coapplicantCurrentIncomeJobTitle,
      monthsAt: toNumber(state.coapplicantCurrentIncomeMonthsAt),
    },
    previous: null,
  };

  const shouldIncludeAdditional = every(
    [
      state.coapplicantAdditionalIncomeType,
      state.coapplicantAdditionalIncomeAmount,
      state.coapplicantAdditionalIncomeFrequency,
    ],
    isNonEmptyString,
  );

  if (shouldIncludeAdditional) {
    coapplicantIncomesDto.additional = {
      additionalIncomeType: state.coapplicantAdditionalIncomeType,
      amount: toNumber(state.coapplicantAdditionalIncomeAmount),
      employerName: null,
      employmentType: EmploymentTypeEnum.OTHER,
      incomeFrequency: incomeFrequencyToAPI(
        state.coapplicantAdditionalIncomeFrequency,
      ),
      jobTitle: null,
      monthsAt: 0,
    };
  }

  const shouldIncludePrevious = every(
    [
      state.coapplicantPreviousIncomeGrossAnnual,
      state.coapplicantPreviousIncomeEmploymentType,
    ],
    isNonEmptyString,
  );

  if (shouldIncludePrevious) {
    coapplicantIncomesDto.previous = {
      amount: toNumber(state.coapplicantPreviousIncomeGrossAnnual),
      employerName: state.coapplicantPreviousIncomeEmployerName,
      employmentType: state.coapplicantPreviousIncomeEmploymentType,
      incomeFrequency: incomeFrequencyToAPI(IncomeFrequencyEnum.ANNUALLY),
      jobTitle: state.coapplicantPreviousIncomeJobTitle,
      monthsAt: DEFAULT_PREVIOUS_INCOME_MONTHS_AT,
    };
  }

  return coapplicantIncomesDto;
};
