import i18n from 'i18next';
import { get as lodashGet, uniq } from 'lodash';
import { StateCreator } from 'zustand';

import { HttpStatusCode } from '../../common';
import { config } from '../../config';
import { DEFAULT_AUTH_PATH } from '../routeStore/constants/authPath';
import { UploadItemType } from './enums/UploadItemType';
import { VerificationStatus } from './enums/VerificationStatus';
import { validateFileFormat } from './functions/validateFileFormat';
import { validateFileSize } from './functions/validateFileSize';
import { SignedUrlResponse } from './types/SignedUrlResponse';
import { UploadItem } from './types/UploadItem';
import { VerificationState } from './types/VerificationState';
import { VerificationStateDependencies } from './types/VerificationStateDependencies';

export const keysToPersist = [
  'pendingUploadTypes',
  'pendingUploadTypesVehicleId',
];

export const verificationStore =
  ({
    getVerifications,
    useApiStore,
    useDashboardStore,
    useUserStore,
  }: VerificationStateDependencies): StateCreator<VerificationState> =>
  (set, get) => ({
    activeVerification: null,
    fileFormatErrorMessage: '',
    fileSizeErrorMessage: '',
    isUploading: false,
    isUploadSuccess: false,
    pendingUploadTypes: [],
    pendingUploadTypesVehicleId: '',
    uploadFailedMessage: '',

    computed: {
      get completeVerificationsCount() {
        const {
          computed: { verifications },
        } = get();

        return verifications.filter(
          (verification) => verification.status === VerificationStatus.COMPLETE,
        ).length;
      },
      get hasMissingFiles() {
        const { activeVerification } = get();

        if (!activeVerification) {
          return;
        }

        return activeVerification.uploadItems.some(
          (uploadItem) => !uploadItem.hasFile,
        );
      },
      get progress() {
        const {
          computed: { completeVerificationsCount, totalVerificationsCount },
        } = get();

        if (totalVerificationsCount === 0) {
          return 0;
        }

        return (completeVerificationsCount / totalVerificationsCount) * 100;
      },
      get totalVerificationsCount() {
        const {
          computed: { verifications },
        } = get();

        return verifications.filter(
          (verification) => verification.status !== VerificationStatus.OPTIONAL,
        ).length;
      },
      get uploadedTypes() {
        const {
          computed: { vehicleApplicationUploadedTypes },
        } = useDashboardStore.getState();

        const { pendingUploadTypes } = get();

        return uniq(vehicleApplicationUploadedTypes.concat(pendingUploadTypes));
      },
      get verificationCompleted() {
        const { activeVerification } = get();

        return (
          activeVerification?.status === VerificationStatus.COMPLETE || false
        );
      },
      get verifications() {
        return getVerifications(useDashboardStore.getState().computed);
      },
    },

    addFile: (uploadItem: UploadItem, file: File) => {
      const { activeVerification } = get();

      if (!uploadItem || !file || !activeVerification) {
        return;
      }

      const fileSizeError = validateFileSize(file);
      if (fileSizeError) {
        set({ fileSizeErrorMessage: validateFileSize(file) });
        return;
      }

      const fileFormatError = validateFileFormat(file);
      if (fileFormatError) {
        set({ fileFormatErrorMessage: fileFormatError });
        return;
      }

      const uploadItemToUpdate = activeVerification.uploadItems.find(
        (item) => item === uploadItem,
      );

      if (!uploadItemToUpdate) {
        return;
      }

      uploadItemToUpdate.setFile(file);

      set({ activeVerification });
    },
    addPendingUploadTypes: (uploadTypes: UploadItemType[]) => {
      const { pendingUploadTypes } = get();

      set({
        pendingUploadTypes: uniq(pendingUploadTypes.concat(uploadTypes)),
      });
    },
    clearFileErrorMessages: () => {
      set({ fileFormatErrorMessage: '', fileSizeErrorMessage: '' });
    },
    detect: (route: string) => {
      const {
        computed: { verifications },
      } = get();

      const activeVerification = verifications.find(
        (verification) => verification.path === route,
      );

      if (activeVerification) {
        set({ activeVerification });
      }
    },
    getSignedUrl: (uploadItem: UploadItem) => {
      if (!uploadItem || !uploadItem.metadata) {
        return;
      }

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest.post<never, SignedUrlResponse>(
        `/documents/signed-url`,
        uploadItem.metadata,
        { timeout: config.fileUploadTimeout },
      );
    },
    loader: async (
      params: Record<string, string>,
      request: Record<string, string>,
    ) => {
      const {
        pendingUploadTypesVehicleId,
        detect,
        removePendingUploadTypes,
        resetPendingUploadTypes,
      } = get();

      const isVehicleEmpty =
        useDashboardStore.getState().computed.isVehicleEmpty;

      // Fetch vehicle if it's not already loaded
      if (isVehicleEmpty) {
        await useDashboardStore.getState().fetchVehicleById(params.vehicleId);
      }

      const fetchVehicleByIdFailed =
        useDashboardStore.getState().fetchVehicleByIdFailedMessage;

      // Redirect to default authenticated path if vehicle fetch failed
      if (fetchVehicleByIdFailed) {
        const redirectUrl = new URL(DEFAULT_AUTH_PATH, window.location.origin);

        return Response.redirect(redirectUrl.toString(), HttpStatusCode.FOUND);
      }

      const vehicleId = useDashboardStore.getState().computed.vehicleId;
      const uploadedTypes =
        useDashboardStore.getState().computed.vehicleApplicationUploadedTypes;

      if (vehicleId === pendingUploadTypesVehicleId) {
        // Remove any pending upload types that have been uploaded
        removePendingUploadTypes(uploadedTypes);
      } else {
        // Reset state to clear any pending upload types from previously accessed vehicle
        resetPendingUploadTypes();
      }

      set({ pendingUploadTypesVehicleId: vehicleId });

      detect(new URL(request.url).pathname);

      return null;
    },
    notifyUploaded: (uploadItem: UploadItem) => {
      if (!uploadItem || !uploadItem.metadata) {
        return;
      }

      const { userFirstName, userLastName } = useUserStore.getState().computed;

      const documentSubtypeName = [
        userFirstName,
        userLastName,
        'uploaded',
        uploadItem.metadata.uploadIdentifier,
      ]
        .filter(Boolean)
        .join(' ');

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest.post('/documents/notify', {
        autopayNumber: uploadItem.metadata.autopayNumber,
        documentSubtypeName,
      });
    },
    removePendingUploadTypes: (uploadTypes: UploadItemType[]) => {
      const { pendingUploadTypes } = get();

      set({
        pendingUploadTypes: pendingUploadTypes.filter(
          (type) => !uploadTypes.includes(type),
        ),
      });
    },
    reset: () => {
      set({
        activeVerification: null,
        fileFormatErrorMessage: '',
        fileSizeErrorMessage: '',
        isUploading: false,
        isUploadSuccess: false,
        uploadFailedMessage: '',
      });
    },
    resetPendingUploadTypes: () => {
      set({ pendingUploadTypes: [], pendingUploadTypesVehicleId: '' });
    },
    upload: (uploadItems: UploadItem[]) => {
      const {
        addPendingUploadTypes,
        getSignedUrl,
        notifyUploaded,
        uploadSignedUrl,
      } = get();

      set({
        isUploading: true,
        isUploadSuccess: false,
      });

      const promises = uploadItems.map((uploadItem) =>
        getSignedUrl(uploadItem)
          .then(uploadSignedUrl(uploadItem))
          .then(notifyUploaded),
      );

      return Promise.all(promises)
        .then(() => {
          addPendingUploadTypes(uploadItems.map((item) => item.type));

          set({ isUploadSuccess: true });
        })
        .catch((error: any) => {
          const code = lodashGet(error, 'code', null);
          const message = lodashGet(error, 'message', '');

          let uploadFailedMessage = i18n.t(
            'VerificationUploadPage.uploadFailed',
          );

          if (code === 'ECONNABORTED' && message.includes('timeout')) {
            uploadFailedMessage = i18n.t(
              'VerificationUploadPage.uploadTimeout',
            );
          }

          set({ isUploadSuccess: false, uploadFailedMessage });
        })
        .finally(() => set({ isUploading: false }));
    },
    uploadSignedUrl:
      (uploadItem: UploadItem) =>
      ({ url }: SignedUrlResponse) => {
        if (!uploadItem.hasFile) {
          return;
        }

        const httpRequest = useApiStore.getState().httpRequest();

        return httpRequest
          .put(url, uploadItem.file, {
            headers: {
              'Content-Type': uploadItem.metadata.contentType,
            },
          })
          .then(() => uploadItem);
      },
  });
