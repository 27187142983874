import i18n from 'i18next';
import { get as lodashGet, isEmpty, map, toString } from 'lodash';
import { StateCreator } from 'zustand';

import { ApplicationType } from '../../common';
import { DashboardState } from './types/DashboardState';
import { DashboardStateDependencies } from './types/DashboardStateDependencies';
import { DashboardVehicle } from './types/DashboardVehicle';

export const dashboardStore =
  <TVehicle extends DashboardVehicle>({
    useApiStore,
    Vehicle,
  }: DashboardStateDependencies<TVehicle>): StateCreator<
    DashboardState<TVehicle>
  > =>
  (set, get) => ({
    fetchVehicleByIdFailedMessage: null,
    fetchVehiclesFailedMessage: null,
    fetchVehiclesSuccess: false,
    isLoadingTransition: false,
    vehicle: null,
    vehicles: [],

    computed: {
      get isVehicleEmpty() {
        const { vehicle } = get();

        return isEmpty(vehicle);
      },
      get vehicleApplicationHasCoapplicant() {
        const { vehicle } = get();

        return lodashGet(vehicle, 'application.hasCoApplicant');
      },
      get vehicleApplicationId() {
        const { vehicle } = get();

        return lodashGet(vehicle, 'application.id');
      },
      get vehicleApplicationIsLeaseBuyoutType() {
        const { vehicle } = get();

        return (
          lodashGet(vehicle, 'application.type') ===
          ApplicationType.LEASE_BUYOUT
        );
      },
      get vehicleApplicationIsRefinanceType() {
        const { vehicle } = get();

        return (
          lodashGet(vehicle, 'application.type') === ApplicationType.REFINANCE
        );
      },
      get vehicleApplicationSourceId() {
        const { vehicle } = get();

        return lodashGet(vehicle, 'application.sourceApplicationId');
      },
      get vehicleApplicationUploadedTypes() {
        const { vehicle } = get();

        return lodashGet(vehicle, 'application.verifications', []);
      },
      get vehicleId() {
        const { vehicle } = get();

        return lodashGet(vehicle, 'id');
      },
      get vehiclesLoading() {
        const { vehicles, isLoadingTransition } = get();

        return isEmpty(vehicles) && isLoadingTransition;
      },
    },

    addVehicle(vehicle: TVehicle | null) {
      if (!vehicle) {
        return;
      }

      const { vehicles } = get();

      set({ vehicles: [new Vehicle(vehicle), ...vehicles] });
    },
    fetchVehicleById(vehicleId: string | undefined) {
      if (!vehicleId) {
        return;
      }

      set({
        fetchVehicleByIdFailedMessage: null,
        isLoadingTransition: true,
        vehicle: null,
      });

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .get<never, TVehicle>(`/2.0/vehicles/${vehicleId}`)
        .then((vehicle) => {
          set({ vehicle: new Vehicle(vehicle) });

          return null;
        })
        .catch((error) => {
          const errorStatus = toString(lodashGet(error, 'response.status'));

          const defaultMessage = i18n.t(
            'errors.fetchVehicleById.defaultMessage',
          );

          set({
            fetchVehicleByIdFailedMessage: lodashGet(
              i18n.t('errors.fetchVehicleById.statusMessage'),
              errorStatus,
              defaultMessage,
            ),
          });

          return null;
        })
        .finally(() => set({ isLoadingTransition: false }));
    },
    fetchVehicles() {
      const portalHttpRequest = useApiStore.getState().portalApi();

      set({
        fetchVehiclesFailedMessage: null,
        fetchVehiclesSuccess: false,
        isLoadingTransition: true,
      });

      return portalHttpRequest
        .get<never, TVehicle[]>('/2.0/vehicles')
        .then((vehicles) => map(vehicles, (vehicle) => new Vehicle(vehicle)))
        .then((vehicles) => set({ fetchVehiclesSuccess: true, vehicles }))
        .catch((error) => {
          const errorStatus = toString(lodashGet(error, 'response.status'));

          const defaultMessage = i18n.t('errors.fetchVehicles.defaultMessage');

          set({
            fetchVehiclesFailedMessage: lodashGet(
              i18n.t('errors.fetchVehicles.statusMessage'),
              errorStatus,
              defaultMessage,
            ),
          });
        })
        .finally(() => set({ isLoadingTransition: false }));
    },
  });
